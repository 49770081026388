import React, { FC } from 'react'
import { Link } from 'gatsby-plugin-react-i18next'
import SemiRoundedButton, {
  ButtonSizes,
} from 'src/components/ui/SemiRoundedButton'
import apiKeys from 'src/components/cms-page/api-keys'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

export interface IContentLinkBlock {
  text: string
  hyperlink: string
  apiKey: apiKeys.gatsbyLink
  id: string
}

interface IGatsbyLinkProps {
  contentBlockItem: IContentLinkBlock
  audio?: IAudio | null
}

const GatsbyLink: FC<IGatsbyLinkProps> = ({ contentBlockItem, audio }) => (
  <div className="bg-white">
    <div className="container overflow-auto	">
      <div className="text-center mb-7 mt-7 lg:mt-14">
        <Link key={contentBlockItem.id} to={contentBlockItem.hyperlink}>
          <SemiRoundedButton isPlain size={ButtonSizes.medium}>
            {contentBlockItem.text}
          </SemiRoundedButton>
        </Link>
      </div>
      <AudioPlayer content={audio} className="w-64 mt-16" />
    </div>
  </div>
)

export default GatsbyLink
