import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import Section from 'src/components/typography/Section'
import { pagePadding } from 'src/components/layout/ContentPageContainer'
import ContentBlockSwitcher, {
  IContentBlock,
} from 'src/components/cms-page/ContentBlockSwitcher'
import apiKeys from 'src/components/cms-page/api-keys'
import styled from 'styled-components'
import tw from 'twin.macro'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

interface ISeo {
  title: string
  image: {
    url: string
  }
  twitterCard: string
}

interface ICmsPageProps {
  pageContext: {
    contentBlocks: IContentBlock[]
    seo: ISeo
    pageTitle: string
    mobilePageTitle?: string
    canonicalUrl?: string
    audio?: IAudio | null
  }
}

const StyledSection = styled(Section)`
  ${tw`mb-0`}
`

const marginAfterSections = <div className="h-24 bg-white" />

const isTwoHtmlBlocksInRow = (
  blocks: Array<IContentBlock>,
  currentBlockIndex: number
) => {
  if (blocks[currentBlockIndex].apiKey === 'news_html') {
    const isPreviousSectionHtmlBlock =
      blocks[currentBlockIndex - 1] &&
      blocks[currentBlockIndex - 1].apiKey === 'news_html'

    return isPreviousSectionHtmlBlock
  }

  return false
}

const CmsPageProps = ({
  pageContext: { contentBlocks, seo, pageTitle, mobilePageTitle, canonicalUrl, audio },
}: ICmsPageProps) => {
  const mappedSectionsToComponents = () => {
    let blockWithAudioIndex = contentBlocks.length - 1
    if (audio) {
      while (blockWithAudioIndex >= 0 && 
          contentBlocks[blockWithAudioIndex].apiKey === apiKeys.transitionImage
      ) blockWithAudioIndex--
      if (blockWithAudioIndex < 0) {
        if (contentBlocks.length) {
          blockWithAudioIndex = contentBlocks.length - 1
        } else {
          return (
            <div className="container">
              <AudioPlayer content={audio} className="w-64" />
            </div>
          )
        }
      }
    } else {
      blockWithAudioIndex = -1
    }
    
    return contentBlocks.map((contentBlock, index) => {
      const isMarginTop = isTwoHtmlBlocksInRow(contentBlocks, index)
      const audioContent = index === blockWithAudioIndex ? audio : undefined
      return (
        <ContentBlockSwitcher
          extraMarginTop={isMarginTop}
          key={contentBlock.id}
          contentBlockItem={contentBlock}
          audio={audioContent}
        />
      )
    })
  }

  const getPageTitle = () => (
    <span>
      <span className="hidden md:inline">{pageTitle}</span>
      <span className="md:hidden">{mobilePageTitle ?? pageTitle}</span>
    </span>
  )

  const isLastSectionWithParallax = () => {
    const lastSection = contentBlocks[contentBlocks.length - 1]

    return lastSection.apiKey && lastSection.apiKey === apiKeys.transitionImage
  }

  return (
    <>
      <Helmet>
        <title>{seo && seo.title}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <Layout>
        <div className={`min-h-screen text-deepDarkBlue ${pagePadding}`}>
          <StyledSection>
            <>
              <div className="container bg-white">
                <PageTitle>{getPageTitle()}</PageTitle>
              </div>
              {mappedSectionsToComponents()}
              {isLastSectionWithParallax() ? <></> : marginAfterSections}
            </>
          </StyledSection>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default CmsPageProps
