import React, { FC } from 'react'
import TextHightlight from 'src/components/typography/TextHightlight'
import tw from 'twin.macro'
import styled from 'styled-components'

interface IHighlightedSubtitleProps {
  children: string | JSX.Element | JSX.Element[]
  isSticked?: boolean
  className?: string
}

const HighlightedSubtitleStyles = tw`
    text-left font-thin text-deepDarkBlue tracking-wider
    text-3xl mb-5 sm:text-4xl md:text-5xl
`

export const PlainSubtitle = styled.h3`
  ${HighlightedSubtitleStyles}
`

const HighlightedSubtitle: FC<IHighlightedSubtitleProps> = ({
  children,
  className,
}) => (
  <h3 className={`text-start font-thin text-deepDarkBlue tracking-wider text-3xl mb-5 sm:text-4xl md:text-5xl ${className}`}
  >
    <TextHightlight className="">
      {typeof children === 'string' ? <div>{children}</div> : children}
    </TextHightlight>
  </h3>
)

export default HighlightedSubtitle
