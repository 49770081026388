import React, { FC } from 'react'
import { IImage } from 'src/components/parallax-image'
import apiKeys from 'src/components/cms-page/api-keys'
import AudioPlayer from '../audio/AudioPlayer'
import { IAudio } from '../audio/audio-types'

export interface ITrialProtocols {
  id: string
  apiKey: apiKeys.trialProtocol
  protocolADesktop: IImage
  protocolAMobile: IImage
  protocolBDesktop: IImage
  protocolBMobile: IImage
  protocolA: {
    url: string
  }
  protocolB: {
    url: string
  }
}

interface ITrialProtocolsProps {
  contentBlock: ITrialProtocols
  audio?: IAudio | null
}

const TrialProtocols: FC<ITrialProtocolsProps> = ({ contentBlock, audio }) => {
  const protocolComponents = () => (
    <div className="flex flex-wrap sm:flex-nowrap items-center justify-between mt-8 mb-8">
      {contentBlock.protocolADesktop && contentBlock.protocolA && (
        <a
          href={contentBlock.protocolA.url}
          rel="noreferrer"
          className="w-1/2 hidden sm:block pr-3"
          target="_blank"
        >
          <img
            src={contentBlock.protocolADesktop.url}
            className="w-full block mr-2"
            alt="Protocol A"
          />
        </a>
      )}
      {contentBlock.protocolAMobile && contentBlock.protocolA && (
        <a
          href={contentBlock.protocolA.url}
          rel="noreferrer"
          className="w-full sm:hidden"
          target="_blank"
        >
          <img
            src={contentBlock.protocolAMobile.url}
            className="w-full block"
            alt="Protocol A"
          />
        </a>
      )}
      {contentBlock.protocolBDesktop && contentBlock.protocolB && (
        <a
          href={contentBlock.protocolB.url}
          rel="noreferrer"
          className="w-1/2 hidden sm:block pl-3"
          target="_blank"
        >
          <img
            src={contentBlock.protocolBDesktop.url}
            className="w-full block"
            alt="Protocol A"
          />
        </a>
      )}
      {contentBlock.protocolBMobile && contentBlock.protocolB && (
        <a
          href={contentBlock.protocolB.url}
          rel="noreferrer"
          className="w-full block sm:hidden mt-8"
          target="_blank"
        >
          <img
            src={contentBlock.protocolBMobile.url}
            className="w-full block"
            alt="Protocol A"
          />
        </a>
      )}
    </div>
  )

  return (
    <div className="container">
      <div className="bg-white">{protocolComponents()}</div>
      <AudioPlayer content={audio} className="w-64 mt-16" />
    </div>
  )
}

export default TrialProtocols
