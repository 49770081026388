import React, { FC, useState, useEffect } from 'react'
import InternationalCompetitions from 'src/components/laws/ParticipatingCompetitions/InternationalCompetitions'
import Countries from 'src/components/laws/ParticipatingCompetitions/Countries'
import Organizations from 'src/components/laws/ParticipatingCompetitions/Organizations'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import AudioPlayer from '../../audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

export interface ISplittedNationalCompetitions {
  ongoing: Array<string>
  completed: Array<string>
}
export interface ICountry {
  name: string
  flagImgUrl: string
  splittedNationalCompetitions: ISplittedNationalCompetitions
}

export interface IInternationalCompetition {
  name: string
  logoUrl: string
}

interface ICompetitionGroup {
  label: string
  competitions: Array<IInternationalCompetition>
}

export interface IOrganization {
  name: string
  logoUrl: string
  countries?: Array<ICountry>
  competitionGroups: Array<ICompetitionGroup>
}

interface IParticipatingCompetitionsProps {
  organizations: Array<IOrganization>
  audio?: IAudio | null
}

const ParticipatingCompetitions: FC<IParticipatingCompetitionsProps> = ({
  organizations,
  audio,
}) => {
  const [selectedOrganiztionIndex, setSelectedOrganiztionIndex] = useState(null)

  const { t } = useTranslation('laws')

  useEffect(() => {
    setSelectedOrganiztionIndex(0)
  }, [])

  const selectedOrganization = selectedOrganiztionIndex !== null && 
    organizations[selectedOrganiztionIndex]

  return (
    <div className="container">
      <div className="bg-white">
        <div
          className={`${selectedOrganization === null ? 'mb-40' : 'mb-14'}
          `}
        >
          <Organizations
            organizations={organizations}
            selectedOrganiztionIndex={selectedOrganiztionIndex}
            selectOrganization={(index: number) =>
              setSelectedOrganiztionIndex(index)
            }
          />
          <Countries selectedOrganization={selectedOrganization} />
          { selectedOrganization?.competitionGroups?.map((competitionGroup, index) => 
              <InternationalCompetitions 
                label={competitionGroup.label}
                competitions={competitionGroup.competitions}
                key={index}
              />
            )
          }
          {selectedOrganization !== null &&
          !selectedOrganization.countries &&
          !selectedOrganization.competitionGroups
            ?.some((group) => group.competitions.length) ? (
            <p>{t('None')}</p>
          ) : (
            <></>
          )}
        </div>
        <AudioPlayer content={audio} className="w-64 mt-16" />
      </div>
    </div>
  )
}

export default ParticipatingCompetitions
