import React, { FC, useState } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import HeightAnimation from 'src/components/layout/HeightAnimation'
import { StyledListWrapper } from 'src/components/typography/List'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

export interface IQuestionAndAnswerProps {
  question: string
  answer: string
  audio?: IAudio | null
}

interface IStyledQuestionProps {
  isOpen: boolean
}

const StyledQuestion = styled.div<IStyledQuestionProps>`
  & h2 {
    ${tw`text-base italic text-left font-bold p-0 py-2 m-0 border-0`}
  }
`

const StyledQnAContainer = styled.div`
  &:last-child {
    ${tw`mb-32`}
  }
`

const StyledAnswer = styled.div`
  body & * {
    ${tw`text-base`}
  }
  p,
  span,
  li {
    ${tw`text-grayBlue`}
  }
  ul li {
    &:before {
      top: 0.55rem;
      ${tw`bg-grayBlue`}
    }
  }
`

const QuestionAndAnswer: FC<IQuestionAndAnswerProps> = ({
  question,
  answer,
  audio,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledQnAContainer className="bg-white">
      <div className="container">
        <div className="border-0 border-b border-navyBlueGray">
          <button
            type="button"
            onClick={() => setIsOpen(!isOpen)}
            className="block w-full"
          >
            <CmsHtmlContent>
              <StyledQuestion
                isOpen={isOpen}
                dangerouslySetInnerHTML={{ __html: question }}
              />
            </CmsHtmlContent>
          </button>
          <HeightAnimation isOpen={isOpen}>
            <StyledAnswer>
              <StyledListWrapper>
                <CmsHtmlContent>
                  <div
                    className="bg-lightGray p-4 md:p-6"
                    dangerouslySetInnerHTML={{ __html: answer }}
                  />
                </CmsHtmlContent>
              </StyledListWrapper>
            </StyledAnswer>
          </HeightAnimation>
        </div>
        <AudioPlayer content={audio} className="w-64 mt-16" />
      </div>
    </StyledQnAContainer>
  )
}

export default QuestionAndAnswer
