import React, { FC } from 'react'
import HighlightedSubtitle from 'src/components/typography/HighlightedSubtitle'
import apiKeys from 'src/components/cms-page/api-keys'
import styled from 'styled-components'
import tw from 'twin.macro'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

export interface IHighlightBlock {
  id: string
  apiKey: apiKeys.highlight
  highlight: string
  extraTopMargin: boolean
  isBiggerOnMobile: boolean
}

interface IHighlightProps {
  contentBlock: IHighlightBlock
  audio?: IAudio | null
}
interface IStyledHighlightProps {
  isBiggerOnMobile: boolean
}

const StyledHighlight = styled.div<IStyledHighlightProps>`
  span {
    ${({ isBiggerOnMobile }) => (isBiggerOnMobile ? tw`text-5xl` : '')}
  }
`

const Highlight: FC<IHighlightProps> = ({ contentBlock, audio }) => (
  <div className="bg-white">
    <StyledHighlight
      isBiggerOnMobile={contentBlock.isBiggerOnMobile}
      className="container overflow-auto"
    >
      <HighlightedSubtitle
        className={`md:-ml-6 -ml-2 ${
          contentBlock.extraTopMargin ? 'mt-14' : 'mt-5'
        }`}
      >
        <span className="whitespace-pre-line text-left leading-tight">
          {contentBlock.highlight}
        </span>
      </HighlightedSubtitle>
      <AudioPlayer content={audio} className="w-64 mt-16" />
    </StyledHighlight>
  </div>
)

export default Highlight
