import React, { FC } from 'react'
import { CmsHtmlContentGrayUnderline } from 'src/components/typography/CmsHtmlContentFormats'
import { StyledListWrapper } from 'src/components/typography/List'
import apiKeys from 'src/components/cms-page/api-keys'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IAudio } from 'src/components/audio/audio-types'

export interface IContentHtmlBlock {
  apiKey: apiKeys.newsHtml
  id: string
  htmlContent: string
}

interface INewsHtmlProps {
  contentBlockItem: IContentHtmlBlock
  extraMarginTop: boolean
  audio?: IAudio | null
}

const NewsHtml: FC<INewsHtmlProps> = ({ contentBlockItem, extraMarginTop, audio }) => (
  <div className="bg-white">
    <div className={`container ${extraMarginTop ? 'mt-14' : ''}`}>
      <StyledListWrapper>
        <CmsHtmlContentGrayUnderline
          dangerouslySetInnerHTML={{ __html: contentBlockItem.htmlContent }}
        />
      </StyledListWrapper>
      <AudioPlayer content={audio} className="w-64 mt-16" />
    </div>
  </div>
)

export default NewsHtml
