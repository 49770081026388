import React, { FC, useEffect } from 'react'
import styled from 'styled-components'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import tw from 'twin.macro'
import AudioPlayer from '../audio/AudioPlayer'
import { IAudio } from '../audio/audio-types'

const tailwind = resolveConfig(tailwindConfig)

export interface IImage {
  alt: string
  url: string
  width: number
  height: number
}

interface IParallaxImageProps {
  desktopImage: IImage
  mobileImage: IImage
  id: string
  className?: string
  isGrayBlueBackground?: boolean
  isNavyBlueGrayBackground?: boolean
  audio?: IAudio | null
}

interface IStyledParallax {
  desktop: IImage
  mobile: IImage
}

const viewportHeightInVh = 100
const parallaxSectionHeightInVh = 95

const ParallaxImage: FC<IParallaxImageProps> = ({
  desktopImage,
  mobileImage,
  id,
  className,
  isGrayBlueBackground,
  isNavyBlueGrayBackground,
  audio
}) => {
  const StyledParallaxContainer = styled.div`
    &:last-child {
      color: #dedede;
      .parallax-margin {
        ${tw`pb-0 md:pb-0`}
      }
    }
  `

  const StyledParallax = styled.div`
    height: ${parallaxSectionHeightInVh}vh;
    &:last-child {
      .parallax-image {
        ${tw`pb-0 md:pb-0`}
      }
    }
  `

  const StyledParallaxImage = styled.div`
    height: 100vh;
    max-height: 0;
  `

  const StyledParallaxBackground = styled.div<IStyledParallax>`
    background-image: url(${({ mobile }) =>
      mobile && mobile.url}?auto=format&q=90);
    @media (min-width: ${tailwind.theme.screens.lg}) {
      background-image: url(${({ desktop }) =>
        desktop && desktop.url}?auto=format&q=90);
    }
  `

  const getBgClass = () => {
    if (isGrayBlueBackground) {
      return 'bg-grayBlue'
    }

    if (isNavyBlueGrayBackground) {
      return 'bg-navyBlueGray'
    }

    return 'bg-white'
  }

  const isSectionOnScreen = () => {
    const windowHeight = window.innerHeight
    const element = document.getElementById(id)
    const bounding = element.getBoundingClientRect()

    const top = bounding.top - windowHeight
    const { bottom } = bounding

    const performanceOffsetInPx = 0

    return top - performanceOffsetInPx < 0 && bottom + performanceOffsetInPx > 0
  }

  const updateHeight = () => {
    const element = document.getElementById(id)
    const sectionHeight = element.offsetHeight
    const { top } = element.getBoundingClientRect()
    const parallaxImageDiv = document.querySelector(`#${id} .parallax-image`)

    const parallaxSectionOffset = `${
      viewportHeightInVh - parallaxSectionHeightInVh
    }vh`
    const performanceOffsetInPx = '200px'
    const maxHeight = sectionHeight - top

    parallaxImageDiv.style.maxHeight = `calc(${maxHeight}px + ${parallaxSectionOffset} + ${performanceOffsetInPx})`
  }

  const stickBackground = () => {
    const element = document.getElementById(id)

    if (isSectionOnScreen()) {
      element.style.visibility = 'visible'
      updateHeight()
      return
    }

    element.style.visibility = 'hidden'
  }

  useEffect(() => {
    stickBackground()
    window.addEventListener('scroll', stickBackground)

    return () => {
      window.removeEventListener('scroll', stickBackground)
    }
  })

  return (
    <StyledParallaxContainer className={`${className}`}>
      <div className={`parallax-margin pt-10 md:pt-20 ${getBgClass()}`} />
      <StyledParallax id={id} className="w-full flex flex-col-reverse">
        <StyledParallaxImage className="parallax-image -z-10 fixed left-0 right-0 bottom-0 bg-paleBlue overflow-hidden">
          <StyledParallaxBackground
            className="absolute bottom-0 left-0 w-screen h-screen bg-cover bg-center"
            desktop={desktopImage}
            mobile={mobileImage}
          />
        </StyledParallaxImage>
        <AudioPlayer content={audio} className="w-64 mb-8 ml-8" />
      </StyledParallax>
      <div className={`parallax-margin pb-10 md:pb-20 ${getBgClass()}`} />
    </StyledParallaxContainer>
  )
}

export default ParallaxImage
