import React, { FC } from 'react'
import apiKeys from 'src/components/cms-page/api-keys'
import AudioPlayer from 'src/components/audio/AudioPlayer'
import { IImage } from 'src/components/parallax-image'
import { IAudio } from 'src/components/audio/audio-types'

export interface IImageBlock {
  apiKey: apiKeys.image
  id: string
  mobileImage: IImage
  desktopImage: IImage
  isBottomSeparatorLine: boolean
}

interface IImageProps {
  contentBlock: IImageBlock
  audio?: IAudio | null
}

const Image: FC<IImageProps> = ({ contentBlock, audio }) => (
  <div className="bg-white">
    <div className="container">
      <img
        src={contentBlock.mobileImage.url}
        alt="Placeholder"
        className={`md:hidden ${
          contentBlock.isBottomSeparatorLine
            ? 'border-grayBlue border-b mb-12'
            : ''
        }`}
      />
      <img
        src={contentBlock.desktopImage.url}
        alt="Placeholder"
        className={`hidden md:block ${
          contentBlock.isBottomSeparatorLine
            ? 'border-grayBlue border-b mb-12'
            : ''
        }`}
      />
      <AudioPlayer content={audio} className="w-64 mt-16" />
    </div>
  </div>
)

export default Image
